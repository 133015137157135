( function() {
    const modalClose = document.querySelector('.modal--trailer .modal_close');
    const modalTitle = document.querySelector('.modal--trailer .modal_title');
    const iframe = document.querySelector('.modal iframe');
    const body = document.querySelector('body');
    let trailerButtons = document.querySelectorAll('.trailer'); 
    if(trailerButtons.length==0){return;}
    fetch(`https://vimeo.com/api/oembed.json?url=${trailerButtons[0].dataset.trailer}`)
    .then(response=>response.json())
    .then(data=>{
        iframe.src=`https://player.vimeo.com/video/${data.video_id}`;
        var player = new Vimeo.Player(iframe);
        player.setColor('#02837c');
        /*getting trailer buttons again, to account for clones in slick slider*/
        trailerButtons = document.querySelectorAll('.trailer');
        trailerButtons.forEach(trailer=>trailer.addEventListener('click',playTrailer));
        modalClose.addEventListener('click',hideModal);
        function playTrailer(e){
            e.preventDefault();
            var trailerURL=e.currentTarget.dataset.trailer;
            fetch(`https://vimeo.com/api/oembed.json?url=${trailerURL}`)
            .then(response=>response.json())
            .then(data=>{
                trailerId=data.video_id;
                player.getVideoId().then(id=>{
                    if(trailerId==id){
                        body.classList.add('modal_open');
                        player.getVideoTitle().then(title=>{
                            modalTitle.innerHTML=title;
                            return;
                        })
                    }
                });
                player.loadVideo(trailerId)
                .then((id)=>{
                    body.classList.add('modal_open');
                    player.getVideoTitle().then(title=>{
                        modalTitle.innerHTML=title;
                    })
                })
                .catch(error=>{
                    console.log(error.name);
                });
            })
            
        
        }
        function hideModal(e){
            e.preventDefault();
            player.unload();
            body.classList.remove('modal_open');
        }
    })
})();

